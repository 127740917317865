<script>
import { onMounted, ref } from "vue";
import { getTop10kPlaylists } from "@/api/playlists.api";
import { ElNotification } from "element-plus";
import PlaylistTable from "@/common/components/PlaylistTable.vue";
export default {
  name: "Playlists",
  components: { PlaylistTable },
  setup() {
    const loading = ref(false);
    const tableData = ref([]);
    onMounted(() => {
      document.title = "Playlists";
      getPlaylistsTableData();
    });

    const getPlaylistsTableData = async () => {
      try {
        loading.value = true;
        const { data } = await getTop10kPlaylists();
        tableData.value = data && data.data ? data.data : [];
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error in fetching playlists",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      tableData,
    };
  },
};
</script>
<template>
  <div class="d-flex page-heading">Playlists</div>
  <PlaylistTable
    :playlist-data="tableData"
    :playlist-loading="loading"
    :card-title="''"
  />
</template>
